/** 工单路由 */
export default {
  path: '/',
  name: 'workOrderHome',
  component: () => import(/* webpackChunkName: "layout" */ '@/views/layout'),
  // meta: {
  //   title: 'home',
  //   icon: 'home'
  // },
  children: [
    {
      path: '/workOrderData',
      name: 'workOrderData',
      component: () => import(/* webpackChunkName: "welcome" */'@/views/workOrder'),
      meta: {
        title: 'workOrderData',
        icon: 'workOrderData',
        parent: '/workOrderData',
        userRouter: true,
        index: 6
      },
      children: [
        // 处置
        {
          path: '/workOrderData/workOrderDisposition',
          name: 'workOrderDisposition',
          component: () => import(/* webpackChunkName: "settlementLatest" */'@/views/workOrder/disposition'),
          meta: {
            keepAlive: true,
            title: 'workOrderDisposition',
            icon: 'data',
            parent: '/workOrderData',
          }
        },
        // 审核
        {
          path: '/workOrderData/workOrderVerify',
          name: 'workOrderVerify',
          component: () => import(/* webpackChunkName: "settlementLatest" */'@/views/workOrder/verify'),
          meta: {
            keepAlive: true,
            title: 'workOrderVerify',
            icon: 'data',
            parent: '/workOrderData',
          }
        },
      ]
    },

  ]

}