import { watch } from 'vue'
import store from '@/store'
import i18n from '@/i18n'



/**
 * 生成路由的标题
 * @param {} title 
 * @returns 
 */
export function generateTitle(title) {
  return i18n.global.t('msg.route.' + title)
}

export function getI18nStr(str) {
  let strRe = str.replaceAll(' ','')
  return i18n.global.t(strRe)
}

/**
 *
 * @param  {...any} cbs 所有的回调
 */
export function watchSwitchLang(...cbs) {
  watch(
    () => store.getters.language,
    () => {
      cbs.forEach(cb => cb(store.getters.language))
    }
  )
}
