import router from '@/router'
import store from "@/store";
import {findUserInfo} from "@/api/sys/user";
import {getNavigationRouter} from "@/utils/route";
import {installAllDict} from "@/utils/cache";
import { vModelText } from 'vue';
import {LoadingBar} from "view-ui-plus"

// 白名单 不做拦截的请求
const whiteList = ['/login']
let longOutFlag = false
LoadingBar.config({
  color: "#83E5A8",
  height: 2,
})
/**
 *  路由前置守卫 路由鉴权 全局路由守卫
 *  @param {*} to 要跳转到哪里去
 *  @param {*} from 你从哪里来
 *  @param {*} next 是否要去？
 */
router.beforeEach(async (to, from, next) => {
  //keep-alive 导致tip残留
  //跳转页面时清除tip
  try {
    let tooltipList = document.getElementsByClassName('ivu-tooltip-popper')
    for (let tip in tooltipList) {
      let tipItem = tooltipList[tip]
      if (typeof tipItem == 'object') {
        tipItem.style.display = 'none'
      }
    }
  } catch (error) {
    // console.log('error--tooltip', error)
  }
  LoadingBar.start()
  // 1. 用户已登录，则不允许进入 login
  // 2. 用户未登录，只允许进入login
  if (store.getters.token) {
    // 1. 用户已登录，则不允许进入 login，默认进入首页
    if (to.path === '/login') {
      next('/home')
    } else {
      // 判断用户资料是否存在，如果不存在，则获取用户信息
      if (JSON.stringify(store.getters.userEntity) === '{}') {
        if (longOutFlag) {
          next()
        }
        if (store.getters.userId === '' || store.getters.userId === 'undefined'){
          longOutFlag = true
          store.dispatch('user/logout')
          next("/login")
        }
        // 获取用户信息
        await findUserInfo(store.getters.userId).then(async resData => {
          store.commit('user/setLoginInfo',resData.data)
          // store.commit('user/setUserEntity',resData.data.userEntity)
          const filterRoutes = await store.dispatch(
            'permission/filterRoutes',
            resData.data.operationEntities
          )
          // 循环添加对应动态路由
          filterRoutes.forEach(item => {
            router.addRoute(item)
          })
          await installAllDict()    // 获取所有字典
          store.commit('user/setNavigationRouter',getNavigationRouter())
          // 添加完动态路由之后，需要在进行一次主动跳转
          next(to.path)
        })
      }
      // 路径为空进入首页
      else if (to.path === '/') {
        next(store.getters.userEntity?.homeRouteAddr ? store.getters.userEntity?.homeRouteAddr :'/home') 
      } else {
        next()
      }
    }
  } else {
    // 2. 用户未登录，只允许进入login
    if (whiteList.indexOf(to.path) > -1) { // 白名单不拦截
      next()  // reutrn 没有参数或返回undefined 默认去要去的路径
    } else { // 用户未登录，不在白名单里面，默认去登录页面
      next("/login")
    }
  }
})


router.afterEach(to => {
  LoadingBar.finish()
})



