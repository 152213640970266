import { createI18n } from 'vue-i18n'
import mZhLocale from './lang/zh'
import mEnLocale from './lang/en'
import store from '@/store'
import zh from "view-ui-plus/dist/locale/zh-CN"
const messages = {
    en: {
        msg: {
            ...mEnLocale
        }
    },
    zh: {
        msg: {
            ...mZhLocale
        },
        i: {
            ...zh.i
        }
    }
}


/**
 * 返回当前 lang
 */
function getLanguage() {
    if(store && store.getters){
        return store.getters.language
    }else{
        return 'zh'
    }
}
const i18n = createI18n({
    // 使用 composition api
    legacy: false,
    // 全局使用 t 函数
    globalInjection: true,
    locale: getLanguage(),
    messages
})

export default i18n