// 定义所有项目中使用的常量
// token
export const TOKEN = 'token'
// 用户id
export const USERID = 'userId'
// token 时间戳
export const TIME_STAMP = 'timeStamp'
// 超时时长(毫秒) 两小时
export const TOKEN_TIMEOUT_VALUE = 2 * 3600 * 1000
// 国际化
export const LANG = 'language'
// 主题色保存的 key
export const MAIN_COLOR = 'mainColor'
// 默认色值
export const DEFAULT_COLOR = '#409eff'
// tags
export const TAGS_VIEW = 'tagsView'
// 数据页当前的tag
export const DATA_CURRENT_TAG = 'dataCurrentTag'

// 在线时间，每五分钟更新一次
export const ON_LINE_TIME = 'onLineTime'



// 简单密码
export const SIMPLE_PASSWORD = "simplePassword"