export default {
  path: '/',
  name: 'sysHome',
  component: () => import(/* webpackChunkName: "layout" */'@/views/layout'),
  // meta: {
  //     keepAlive:true,
  //     title: 'userHome',
  //     icon: 'user'
  // },
  children: [
    {
      path: '/sys',
      name: 'sysMain',
      component: () => import(/* webpackChunkName: "sysManage" */'@/views/sys-manage'),
      meta: {
        title: 'sysMain',
        icon: 'user',
        parent: '/sys',
        showAside: false,
        userRouter: true,
        index: 7
      },
      // redirect: '/sys/user/user-manage',
      children: [
        // {
        //     path:'/sys/user/info/:id',
        //     name: 'userInfo',
        //     component: () => import('@/views/sys-manage/sub-views/user-info'),
        //     meta: {
        //         title: 'userInfo',
        //         icon: 'user',
        //         parent: '/sys',
        //     }
        // },
        // {
        //   path: '/sys/user/organization-manage',
        //   name: 'organizationManage',
        //   component: () => import(/* webpackChunkName: "organizationManage" */'@/views/sys-manage/sub-views/organization-manage'),
        //   meta: {
        //     title: 'organizationManage',
        //     icon: 'organization',
        //     parent: '/sys',
        //   }
        // },
        {
          path: '/sys/user/user-manage',
          name: 'accountManage',
          component: () => import(/* webpackChunkName: "accountManage" */'@/views/sys-manage/sub-views/account-manage'),
          meta: {
            title: 'accountManage',
            icon: 'personnel-manage',
            parent: '/sys',
          }
        },
        {
          path: '/sys/user/role-manage',
          name: 'roleManage',
          component: () => import(/* webpackChunkName: "roleManage" */'@/views/sys-manage/sub-views/role-manage'),
          meta: {
            title: 'roleManage',
            icon: 'role',
            parent: '/sys',
          }
        },

        {
          path: '/sys/permission-manage',
          name: 'permissionManage',
          component: () => import(/* webpackChunkName: "permissionManage" */'@/views/sys-manage/sub-views/permission-manage'),
          meta: {
            title: 'permissionManage',
            icon: 'permission-manage',
            parent: '/sys',
          }
        },
        {
          path: '/sys/user/operation-list',
          name: 'operationManage',
          component: () => import(/* webpackChunkName: "operationManage" */'@/views/sys-manage/sub-views/operation-manage'),
          meta: {
            title: 'operationManage',
            icon: 'operation',
            parent: '/sys',
          }
        },
        {
          path: '/sys/dict-manage',
          name: 'dictManage',
          component: () => import(/* webpackChunkName: "dictManage" */'@/views/sys-manage/sub-views/dict-manage'),
          meta: {
            title: 'dictManage',
            icon: 'dict-manage',
            parent: '/sys',
          }
        },
        {
          path: '/sys/warnSetting',
          name: 'warnSetting',
          component: () => import(/* webpackChunkName: "warnSetting" */'@/views/sys-manage/sub-views/warn-setting'),
          meta: {
            title: 'warnSetting',
            icon: 'warning',
            parent: '/sys',
          }
        },
        {
          path: '/sys/apsAuthorization',
          name: 'apsAuthorization',
          component: () => import(/* webpackChunkName: "apsAuthorization" */'@/views/sys-manage/sub-views/authorization'),
          meta: {
            title: 'apsAuthorization',
            icon: 'authorization',
            parent: '/sys',
          }
        },
        {
          path: '/sys/exceptionManage',
          name: 'exceptionManage',
          component: () => import(/* webpackChunkName: "exceptionManage" */'@/views/sys-manage/sub-views/exception-manage'),
          meta: {
            title: 'exceptionManage',
            icon: 'exceptionManage',
            parent: '/sys',
          }
        },
        {
          path: '/sys/protocolManage',
          name: 'protocolManage',
          component: () => import(/* webpackChunkName: "protocolManage" */'@/views/sys-manage/sub-views/protocol-manage'),
          meta: {
            title: 'protocolManage',
            icon: 'protocolManage',
            parent: '/sys',
          }
        },
        {
          path: '/sys/messageManage',
          name: 'messageManage',
          component: () => import(/* webpackChunkName: "protocolManage" */'@/views/sys-manage/sub-views/message-manage'),
          meta: {
            title: 'messageManage',
            icon: 'messageManage',
            parent: '/sys',
          }
        },
        {
          path: '/sys/tenantManage',
          name: 'tenantManage',
          component: () => import(/* webpackChunkName: "protocolManage" */'@/views/sys-manage/sub-views/tenant-manage'),
          meta: {
            title: 'tenantManage',
            icon: 'tenantManage',
            parent: '/sys',
          }
        },
        {
          path: '/sys/merchantManage',
          name: 'merchantManage',
          component: () => import(/* webpackChunkName: "protocolManage" */'@/views/sys-manage/sub-views/merchant-manage'),
          meta: {
            title: 'merchantManage',
            icon: 'merchantManage',
            parent: '/sys',
          }
        },
        {//版本发布
          path: '/sys/softWareVersion',
          name: 'softWareVersion',
          component: () => import(/* webpackChunkName: "softWareVersion" */'@/views/sys-manage/sub-views/softWareVersion'),
          meta: {
            title: 'softWareVersion',
            icon: 'softWareVersion',
            parent: '/sys',
          }
        },


      ]
    },

  ]

}