/**
 * 权限操作相关 api
 */
import {axiosPost} from "@/utils/request";



/**
 * 获取权限操作列表
 * @returns {Promise<unknown>}
 */
export const getOperationTree = (data) => {
  return axiosPost({
    url: 'ap-system/getOperationTree',
    data: data
  })
}

/**
 * 新增权限操作列表
 */

export const saveOperation = (data) => {
  return axiosPost({
    url: 'ap-system/saveOperation',
    data: data,
    tips: true,
    loading: false
  })
}

/**
 * 修改
 * @param data
 * @returns {Promise<unknown>}
 */
export const modifyOperation = (data) => {
  delete data.operationTime
  delete data.parentId
  delete data.children
  return axiosPost({
    url: 'ap-system/modifyOperation',
    data: data,
    tips: true,
    loading: false
  })
}

/**
 * 删除
 * @param data
 */
export const removeOperation = (data) => {
  return axiosPost({
    url: 'ap-system/removeOperation',
    data: {
      operationId: data.operationId
    },
    tips: false,
    loading: false

  })
}

export const syncOperationFromDev = () => {
  return axiosPost({
    url: '/ap-system/syncOperationFromDev',
    tips: false,
    loading: true
  })
}