import {login, logout} from "@/api/login";
import md5 from 'md5'
import {getItem, removeAllItem, setItem} from "@/utils/storage";
import {TOKEN, USERID,SIMPLE_PASSWORD} from "@/constant";
import router, {resetRouter} from "@/router";
import {getNavigationRouter} from "@/utils/route";
import {setTimeStamp} from "@/utils/auth";
import {installAllDict} from "@/utils/cache";


export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN) || '',
    userId: getItem(USERID) || '',
    userEntity: {},
    loginInfo: {}, // 登录信息
    navigationRouter: [], // 导航路由信息
    routers: [],
    simplePassword: getItem(SIMPLE_PASSWORD) === true ? true:false, // 是否简单密码

  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      setItem(TOKEN, token)
    },
    setUserId(state, userId) {
      state.userId = userId
      setItem(USERID, userId)
    },

    setSimplePassword(state, simplePassword) {
      setItem(SIMPLE_PASSWORD, simplePassword)
      state.simplePassword = simplePassword
      
    },
    setUserEntity(state, userEntity) {
      state.userEntity = userEntity
      state.userId = userEntity.userId
      setItem(USERID, userEntity.userId)

    },
    setLoginInfo(state,loginInfo) {
      state.loginInfo = loginInfo
      this.commit('user/setUserEntity',loginInfo.userEntity)
    },
    setRouters(state, routers) {
      if (state.userId === '' || state.userId === undefined || state.userId === null){
        state.routers = []
      }else{
        state.routers = routers
      }
    },
    setNavigationRouter(state, navigationRouter) {
      state.navigationRouter = navigationRouter
    }


  },
  actions: {
    /**
     * 登录请求动作
     * */
    login(context, userInfo) {
      const {userAccount, userPassword} = userInfo
      return new Promise((resolve, reject) => {
        login({
          "userAccount": userAccount,
          "userPassword": md5(userPassword)
        }).then( async resData => {
          await this.commit('user/setToken', resData.token) // 存入token

          await this.commit('user/setLoginInfo',resData.data)

          // 处理用户权限，筛选出需要添加的路由
          await this.dispatch(
            'permission/filterRoutes',
            resData.data.operationEntities
          ).then(async resData1 => {
            // 循环添加对应动态路由
            resData1.forEach(item => {
              router.addRoute(item)
            })
            this.commit('user/setNavigationRouter', getNavigationRouter())
            await installAllDict()    // 获取所有字典
            // 保存登录时间
            setTimeStamp()
            resolve(resData)
          })
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * 退出登录
     */
    logout() {
      removeAllItem() // 清理本地缓存
      // this.commit('user/setUserId', '') // 清理用户id
      // this.commit('user/setToken', '') // 清理 token
      // this.commit('user/setUserEntity', {}) // 清理用户信息
      // this.commit('user/setRouters', []) // 清理用户路由
      // this.commit('app/addTagsViewDict',{})
      // TODO: 清理权限相关
      // 重置路由表
      resetRouter()
      logout({})  // 通知后端进行登出
      // router.push('/login') // 跳转首页
      window.location.reload()  // 清空 store
    }

  }
}