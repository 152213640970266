import { TIME_STAMP, TOKEN_TIMEOUT_VALUE } from '@/constant'
import { setItem, getItem } from '@/utils/storage'
/**
 * 获取时间戳
 */
export function getTimeStamp() {
  return getItem(TIME_STAMP)
}
/**
 * 设置时间戳
 */
export function setTimeStamp() {
  setItem(TIME_STAMP, Date.now())
}
/**
 * 是否超时
 */
export function isCheckTimeout() {
  return false
  // 缓存时间戳
  var timeStamp = getTimeStamp()
  if(timeStamp === null) {
    setTimeStamp()
    timeStamp = getTimeStamp()
  }
  // 当前时间戳
  var currentTime = Date.now()
  if (currentTime - timeStamp > TOKEN_TIMEOUT_VALUE) {
    // 超时返回 true
    return true
  }else { // 未超时返回 false 并且 10 分钟更新一次当前时间
    if (currentTime - timeStamp > 10 * 60 * 1000){
      setTimeStamp()
    }
    return false
  }
}
