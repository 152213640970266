<template>
  <div style="display: inline-block">
    <!-- 展示外部图标 -->
    <div v-if="isExternal" :style="styleExternalIcon" class="svg-external-icon svg-icon" :class="className"></div>
    <!--  展示内部图标-->
    <svg v-else class="svg-icon" :style="{width:props.width+'em',height: props.height+'em'}" :class="className" aria-hidden="true">
      <use :xlink:href='iconName' />
    </svg>
  </div>
</template>

<script setup>
import { computed} from 'vue'
import {isExternal as external} from "@/utils/validata";
// 接收父组件参数
const props = defineProps({
  // icon 图标
  icon:{
    type: String,
    required: true
  },
  // 图标类名
  className:{
    type:String,
    default:''
  },
  width: {
    required: false,
    type: Number,
    default: 1
  },
  height: {
    required: false,
    type: Number,
    default: 1
  }
})
/*
*  判断当前图标是否为外部图标
* */
const isExternal = computed(() => external(props.icon))
/*
*  外部图标央视
* */
const styleExternalIcon = computed(() => ({
  mask: `url(${props.icon}) no-repeat 50% 50%`,
  '-webkit-mask':`url(${props.icon}) no-repeat 50% 50%`,
  widht: props.width + 'em',
  height: props.height + 'em',
}))

/*
*  内部图标
* */
const iconName = computed(() => `#icon-${props.icon}`)

</script>

<style lang="less" scoped>
.svg-icon{
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.svg-external-icon{
  background-color: currentColor;
  mask-size: cover !important;
  display: inline-block;
}
</style>