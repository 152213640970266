import { createStore } from 'vuex'
import app from "./modules/app";
import user from './modules/user'
import getters from "./getters";
import theme from "./modules/theme";
import data from "./modules/data"
import permission from "./modules/permission";
import sys from "./modules/sys"
import cache from "./modules/cache"
export default createStore({
  getters,
  mutations: {
    /*
    * 选择tag标签页*/
    mutationSelectTags(state,data){
      let result = false
      for(let i=0; i<state.stateTagsList.length;i++){
        if(state.stateTagsList[i].url == data.url){
          return result = true
        }
      }
      result === false ? state.stateTagsList.push(data) : ''
    },
    /*
    * 关闭tag 标签页*/
    mutationCloseTag(state,data){
      let result = state.stateTagsList.findIndex(item => item.url === data.url)
      state.stateTagsList.splice(result,1)
    }
  },
  actions: {
  },
  modules: {
    user,
    app,
    theme,
    data,
    permission,
    sys,
    cache,
  },

})
