export default {
  path: '/',
  name: 'eqptOpHome',
  component: () => import(/* webpackChunkName: "layout" */'@/views/layout'),
  children: [
    {
      path: '/eqptOp',
      name: 'eqptOpManage',
      component: () => import(/* webpackChunkName: "data" */'@/views/eqptOp'),
      meta: {
        title: 'eqptOpManage',
        icon: 'eqptOpManage',
        parent: '/eqptOpManage',
        userRouter: true,
        index: 2
      },
      children: [
        //  设置控制
        {
          path: '/data/valveControl',
          name: 'valveControl',
          component: () => import(/* webpackChunkName: "valveControl" */'@/views/eqptOp/eqptControl'),
          meta: {
            keepAlive: true,
            title: 'valveControl',
            icon: 'data',
            parent: '/eqptOp'
          }
        },
        // 设备履历
        {
          path: '/data/valveResume',
          name: 'valveResume',
          component: () => import(/* webpackChunkName: "valveResume" */'@/views/eqptOp/valveResume'),
          meta: {
            keepAlive: true,
            title: 'valveResume',
            icon: 'data',
            parent: '/eqptOp',
            subMenu: 'valveResume'
          }
        },
        /** 控制履历lindorm */
        {
          path: '/data/valveResumeLindom',
          name: 'valveResumeLindom',
          component: () => import(/* webpackChunkName: "waterMonthLindom" */'@/views/eqptOp/valveResumeLindom'),
          meta: {
            keepAlive: true,
            title: 'valveResumeLindom',
            icon: 'data',
            parent: '/eqptOp',
            subMenu: 'valveResume'
          }
        },
        /** 井盖报警器 */
        {
          path: '/data/manholeCoverMonitor',
          name: 'manholeCoverMonitor',
          component: () => import(/* webpackChunkName: "eqptInfo" */'@/views/eqptOp/manholeCoverMonitor/index'),
          meta: {
            keepAlive: true,
            title: 'manholeCoverMonitor',
            icon: 'data',
            parent: '/eqptOp',
            subMenu: ''
          }
        },
      ]
    }
  ]
}