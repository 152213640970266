/**
 * 功能按钮权限指令
 */
import store from '@/store'
import {isAdmin} from "@/utils/admin";

function checkPermission(el, binding) {
  // 获取对应的缺陷
  const {value} = binding
  // 获取当前用户的所有功能权限
  const operationList = store.getters.operationList
  if (isAdmin()) {
    return true
  }

  // value 必须是一个数组
  else if (value && value instanceof Array) {
    // 匹配对应的指令
    const hasPermission = operationList.some(point => {
      return value.includes(point)
    })
    // 匹配失败
    if (!hasPermission) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  } else {
    throw new Error('v-permission value is ["admin","editor"...]')
  }
}

/**
 * 判断当前用户是否有按钮权限
 * @param {*} permission 
 * @returns 
 */
export const isUsePermission = permission => {
  if (isAdmin()) {
    return true
  }
  // 获取当前用户的所有功能权限
  const operationList = store.getters.operationList
  return operationList.some(point => {
    return permission.includes(point)
  })
}

/**
 * 判断协议是否有按钮权限
 * @param {String} protocolCode  协议编码
 * @param {String} permission  权限
 */
export const hasProtocolPrimission = (protocolCode, permission) => {
  // 不存在当前协议直接返回false
  if (!store.getters.dictOption?.protocolPermission[protocolCode]) return false

  return store.getters.dictOption?.protocolPermission[protocolCode]?.includes(permission);

} 


export default {
  // 在绑定元素的父组件被挂载之后调用
  mounted(el, binding) {
    checkPermission(el, binding)
  },
  // 在包含组件的 VNode 及子组件的 VNode 更新后调用
  update(el, binding) {
    checkPermission(el, binding)
  }
}