import { feRequest } from "./feRequest"
import {getAllProtocolList} from "@/api/sys/protocol"


const feRequestShort = new feRequest(process.env.VUE_APP_BASE_API, 1000 * 60)

const feRequestLong = new feRequest(process.env.VUE_APP_BASE_API, 1000 * 60 * 60 * 2)

export const axiosGet = (params) => {
  return feRequestShort.axiosGet(params)
}

export const axiosPost = (params) => {
  return feRequestShort.axiosPost(params)
}


export const downfiles = (res) => {
  feRequestShort.downfiles(res)
}


// 导出文件
export const axiosPostDownload = (params) => {
  return feRequestLong.axiosPostDownload(params)
}

/**
 * 下载 http 网址文件
 * @param {*} httpAddr 
 */
export const downFilesByHttp = (httpAddr, name = null) => {
  // 校验token是否过期
  getAllProtocolList().then(data=>{
    if(data.status == 1){
      let a = document.createElement('a')
      document.body.appendChild(a)
      a.href = httpAddr
      a.style.display = 'none'
      if (name !== null) {
        a.download = name
      }
      a.click()
      document.body.removeChild(a)
    }
    // console.log(data)
  })
}



