/**
 * 权限管理 api
 */

import {axiosGet, axiosPost} from '@/utils/request'

/**
 * 获取所有许可
 */
export const findPermission = () => {
  return axiosPost({
    url: 'ap-system/findPermission'
  })
}

/**
 * 添加
 * @param data
 * @returns {Promise<unknown>}
 */
export const savePermission = (data) => {
  return axiosPost({
    url: 'ap-system/savePermission',
    data: data,
    tips: true,
    loading: false
  })
}


/**
 * 修改
 * @param data
 * @returns {Promise<unknown>}
 */
export const modifyPermission = (data) => {
  delete data.permissionCreatetime
  return axiosPost({
    url: 'ap-system/modifyPermission',
    data: data,
    tips: true,
    loading: false
  })
}

/**
 * 删除
 * @param data
 * @returns {Promise<unknown>}
 */
export const removePermission = (data) => {
  return axiosPost({
    url: 'ap-system/removePermission',
    data:{
      permissionId: data.permissionId
    },
    tips: false,
    loading: false
  })
}

/**
 * 许可添加操作
 * @param data
 */
export const addOperation = (data) => {
  return axiosPost({
    url: 'ap-system/savePermissionOperationRel',
    data: {
      permissionId: data.permissionId,
      operationId: data.operationId
    },
    tips: true,
    loading: false
  })
}

/**
 * 获取许可下的操作
 * @param data
 * @returns {Promise<unknown>}
 */
export const getOperation = (data) => {
  return axiosPost({
    url: 'ap-system/getOperationBypermission',
    data: data,
    loading: false
  })
}