// 费用中心
export default {
    path: '/',
    name: 'settlementMgmtHome',
    component: () => import(/* webpackChunkName: "layout" */'@/views/layout'),
    children: [
      {
        path: '/settlementMgmt',
        name: 'settlementMgmt',
        component: () => import(/* webpackChunkName: "settlementMgmt" */'@/views/settlementMgmt'),
        meta: {
          title: 'settlementMgmt',
          icon: 'settlementMgmt',
          parent: '/settlementMgmt',
          userRouter: true,
          index: 5
        },
        children: [
          // 结算数据-最新数据
          {
            path: '/settlementMgmt/settlementLatest',
            name: 'settlementLatest',
            component: () => import(/* webpackChunkName: "settlementLatest" */'@/views/settlementMgmt/settlementLatest'),
            meta: {
              keepAlive: true,
              title: 'settlementLatest',
              icon: 'data',
              parent: '/settlementMgmt',
              subMenu: 'postpaid'
            }
          },
          // 结算数据-历史数据
          {
            path: '/settlementMgmt/settlementHistory',
            name: 'settlementHistory',
            component: () => import(/* webpackChunkName: "settlementHistory" */'@/views/settlementMgmt/settlementHistory'),
            meta: {
              keepAlive: true,
              title: 'settlementHistory',
              icon: 'data',
              parent: '/settlementMgmt',
              subMenu: 'postpaid'
            }
          },
          //自动结算
          {
            path: '/settlementMgmt/autoSettlement',
            name: 'autoSettlement',
            component: () => import(/* webpackChunkName: "autoSettlement" */'@/views/settlementMgmt/autoSettlement'),
            meta: {
              keepAlive: true,
              title: 'autoSettlement',
              icon: 'data',
              parent: '/settlementMgmt',
              subMenu: 'postpaid'
            }
          },
          // 手动结算
          {
            path: '/settlementMgmt/manualSettlement',
            name: 'manualSettlement',
            component: () => import(/* webpackChunkName: "manualSettlement" */'@/views/settlementMgmt/manualSettlement'),
            meta: {
              keepAlive: true,
              title: 'manualSettlement',
              icon: 'data',
              parent: '/settlementMgmt',
              subMenu: 'postpaid'
            }
          },
          // 收据模板
          {
            path: '/settlementMgmt/receiptTem',
            name: 'receiptTem',
            component: () => import(/* webpackChunkName: "receiptTem" */'@/views/settlementMgmt/receiptTem'),
            meta: {
              keepAlive: true,
              title: 'receiptTem',
              icon: 'data',
              parent: '/settlementMgmt',
              showAside: false,
              subMenu: 'settlement'
            }
          },
          // 缴费
          {
            path: '/settlementMgmt/payCharge',
            name: 'payChargeDel',
            component: () => import(/* webpackChunkName: "payChargeDel" */'@/views/settlementMgmt/payCharge'),
            meta: {
              keepAlive: true,
              title: 'payChargeDel',
              icon: 'data',
              parent: '/settlementMgmt',
              subMenu: 'postpaid'
            }
          },
          
          // 余额管理
          {
            path: '/settlementMgmt/recharge',
            name: 'recharge',
            component: () => import(/* webpackChunkName: "recharge" */'@/views/settlementMgmt/prePayFee/recharge'),
            meta: {
              keepAlive: true,
              title: 'recharge',
              icon: 'data',
              parent: '/settlementMgmt',
              subMenu: 'prePayFee'
            }
          },
          // 费用明细
          {
            path: '/settlementMgmt/costDetail',
            name: 'costDetail',
            component: () => import(/* webpackChunkName: "costDetail" */'@/views/settlementMgmt/prePayFee/costDetail'),
            meta: {
              keepAlive: true,
              title: 'costDetail',
              icon: 'data',
              parent: '/settlementMgmt',
              subMenu: 'prePayFee'
            }
          },
          // 欠费管理
          {
            path: '/settlementMgmt/overdue',
            name: 'overdue',
            component: () => import(/* webpackChunkName: "overdue" */'@/views/settlementMgmt/prePayFee/overdue'),
            meta: {
              keepAlive: true,
              title: 'overdue',
              icon: 'data',
              parent: '/settlementMgmt',
              subMenu: 'prePayFee'
            }
          },
          // 预欠费管理
          {
            path: '/settlementMgmt/preOverdue',
            name: 'preOverdue',
            component: () => import(/* webpackChunkName: "preOverdue" */'@/views/settlementMgmt/prePayFee/preOverdue'),
            meta: {
              keepAlive: true,
              title: 'preOverdue',
              icon: 'data',
              parent: '/settlementMgmt',
              subMenu: 'prePayFee'
            }
          },
          // 收费模式
          {
            path: '/settlementMgmt/chargingMode',
            name: 'chargingMode',
            component: () => import(/* webpackChunkName: "chargingMode" */'@/views/settlementMgmt/prePayFee/chargingMode'),
            meta: {
              keepAlive: true,
              title: 'chargingMode',
              icon: 'data',
              parent: '/settlementMgmt',
              subMenu: 'settlement',
              showAside: false
            }
          },
          // 水价管理
          {
            path: '/settlementMgmt/waterPriceMode',
            name: 'waterPriceMode',
            component: () => import(/* webpackChunkName: "waterPriceMode" */'@/views/settlementMgmt/waterPriceMode'),
            meta: {
              keepAlive: true,
              title: 'waterPriceMode',
              icon: 'data',
              parent: '/settlementMgmt',
              subMenu: 'settlement',
              showAside: false
            }
          },
          // 新结算任务
          {
            path: '/settlementMgmt/settleTaskList',
            name: 'settleTaskList',
            component: () => import(/* webpackChunkName: "settleTaskList" */'@/views/settlementMgmt/settleTaskList'),
            meta: {
              keepAlive: true,
              title: 'settleTaskList',
              icon: 'data',
              parent: '/settlementMgmt',
              showAside: false,
              // subMenu: 'settlement'
            }
          },
        ]
      }
    ]
  }