
export default {
  path: '/',
  name: 'home',
  component: () => import(/* webpackChunkName: "layout" */ '@/views/layout'),
  // meta: {
  //   title: 'home',
  //   icon: 'home'
  // },
  children: [
    {
      path: '/home',
      name: 'home',
      component: () => import(/* webpackChunkName: "welcome" */'@/views/welcome'),
      meta: {
        title: 'home',
        icon: 'home',
        parent: '/home',
        showAside: false,
        index: 0
      },
    },

  ]

}