import { createApp } from "vue";
import store from "./store";
import App from "./App.vue";
import router from "./router";
import storePinia from "./stores";
import axios from "axios";
import VueAxios from "vue-axios";
import i18n from "@/i18n";
import installElementPlus from "./plugins/element";
import fztCom from "./components/el";

// 导入视频组件
import VueVideoPlayer from "@videojs-player/vue";
import "video.js/dist/video-js.css";
// 使用 view 组件
import "view-ui-plus/dist/styles/viewuiplus.css";

import "normalize.css";
import "@/styles/index.scss"; // global css
import "@/styles/fanger.scss"; // 方尔 css

// 导入 svgIcon
import installIcons from "@/icons";
// 导入路由鉴权
import "./permission";
// 导入过滤器
import installFilter from "@/filter";
// 导入自定义指令
import installDirective from "@/directives";
// 导入全局组件
import installComponent from "./installComponent";
import { errorMsg } from "@/utils/msgBox.ts";
//监听js错误，也能监听Vue组件的报错
window.onerror = function (message, source, lineno, colno, error) {
  //操作失败请联系管理员
  // console.log(message,error)
  if (
    message == "ResizeObserver loop limit exceeded" ||
    message ==
      "ResizeObserver loop completed with undelivered notifications." ||
    message === "Script error."
  ) {
    return;
  }

  errorMsg("操作失败请联系管理员");
};
// 捕获promise报错
window.addEventListener("unhandledrejection", function (event) {
  // console.log(event);
  if (event.reason.code === "ERR_CANCELED") {
    errorMsg("请勿重复操作");
    return;
  }
  if (event.reason.status === -1) {
    return;
  }

  errorMsg("操作失败请联系管理员");
});
const app = createApp(App);
installElementPlus(app);
installIcons(app);
installFilter(app);
installDirective(app);
// 注册组件
installComponent(app);

app
  .use(i18n)
  .use(store)
  .use(storePinia)
  .use(router)
  .use(VueAxios, axios)
  .use(fztCom)
  .use(VueVideoPlayer)
  .mount("#app");
