import {DATA_CURRENT_TAG, LANG, TAGS_VIEW} from '@/constant'
import {getItem, setItem} from '@/utils/storage'
import {isNull} from "@/utils/fangerUtils";
import {warnMsg} from "@/utils/msgBox.ts";
import {useRouter} from "vue-router";
import router from "@/router";

export default {
  namespaced: true,
  state: () => ({
    // 左侧菜单的伸缩功能实现
    sidebarOpened: true,
    language: getItem(LANG) || 'zh',
    // tagsViewDict: getItem(TAGS_VIEW) || {},
    tagsViewDict: {}, // 标签页字典，保存每个菜单下的标签页
    curTagsViewDict: {}, // 当前标签页字典，保存每个菜单下当前保存的标签页
    data_current_tag: getItem(DATA_CURRENT_TAG) || ''
  }),
  mutations: {
    triggerSidebarOpened(state) {
      state.sidebarOpened = !state.sidebarOpened
    },
    /**
     * 设置国际化
     */
    setLanguage(state, lang) {
      setItem(LANG, lang)
      state.language = lang
    },
    /**
     * 添加 tags
     */
    addTagsViewDict(state, tag) {
      // 清空标签
      if (isNull(tag)) {
        state.tagsViewDict = tag
        return
      }

      if (!(tag.meta.parent in state.tagsViewDict)) {
        state.tagsViewDict[tag.meta.parent] = []
        state.curTagsViewDict[tag.meta.parent] = ''
      }

      state.curTagsViewDict[tag.meta.parent] = tag.path
      const isFind = state.tagsViewDict[tag.meta.parent].find(item => {
        const findFlag = item.path === tag.path
        if (findFlag && item.title !== tag.title) {
          item.title = tag.title
        }
        return findFlag
      })
      // 处理重复
      if (!isFind) {
        if (['sysMain'].indexOf(tag.name) !== -1) return
        state.tagsViewDict[tag.meta.parent].push(tag)
        // setItem(TAGS_VIEW, state.tagsViewDict)
      }
    },
    /**
     * 设置数据当前的tag
     */
    setCurrentTag(state, tag) {
      state.data_current_tag = tag
      setItem(DATA_CURRENT_TAG, state.data_current_tag)
    },
    /**
     * 为指定的 tag 修改 title
     */
    changeTagsView(state, {index, tag}) {
      state.tagsViewDict[tag.meta.parent][index] = tag
      setItem(TAGS_VIEW, state.tagsViewList)
    },
    /**
     * 删除 tag
     * @param {type: 'other'||'right'||'index', index: index} payload
     */
    removeTagsView(state, payload) {
      if (payload.type === 'index') {
        state.tagsViewDict[payload.parent].splice(payload.index, 1)
      } else if (payload.type === 'other') {
        state.tagsViewDict[payload.parent].splice(
          payload.index + 1,
          state.tagsViewDict[payload.parent].length - payload.index + 1
        )
        state.tagsViewDict[payload.parent].splice(0, payload.index)
      } else if (payload.type === 'right') {
        state.tagsViewDict[payload.parent].splice(
          payload.index + 1,
          state.tagsViewDict[payload.parent].length - payload.index + 1
        )
      } else if (payload.type === 'active') {
        // 删除当前节点
        if (state.tagsViewDict[payload.parent].length === 1){
          warnMsg('当前只有一页，无法删除当前页')
        } else {
          state.tagsViewDict[payload.parent].splice(payload.index, 1)
          if (payload.index >= state.tagsViewDict[payload.parent].length){
            router.push(state.tagsViewDict[payload.parent][state.tagsViewDict[payload.parent].length -1].fullPath)
          } else {
            router.push(state.tagsViewDict[payload.parent][payload.index].fullPath)
          }
        }

      }
      setItem(TAGS_VIEW, state.tagsViewDict)
    }
  },
  actions: {}
}
