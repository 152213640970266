/**
 * 系统管理
 */
import {getOperationTree} from "@/api/sys/operation";
import {findPermission} from "@/api/sys/peimission";
import {findRole} from "@/api/sys/role";

/**
 * 迭代操作 子操作列表
 * @param allOps 所有操作
 * @param checkOps 选中的操作
 * @returns {*[]}
 */
const operationIteration = (allOps, checkOps) => {
  const ops = []
  allOps.forEach(op => {
    if (op.children && op.children.length > 0) {
      ops.push(...operationIteration(op.children, checkOps))
    } else {
      if (checkOps.indexOf(op.operationId) !== -1) {
        ops.push(op.operationId)
      }
    }
  })
  return ops

}
export default {
  namespaced: true,
  state: () => ({
    // 所有操作
    allOperations: [],
    // 所有许可
    allPermissions: [],
    // 获取所有角色
    allRoles: []
  }),
  mutations: {
    setAllOperations(state, allOperations) {
      state.allOperations = allOperations
    },
    setAllPermission(state, allPermission) {
      state.allPermissions = allPermission
    },
    setAllRoles(state, allRoles){
      state.allRoles = allRoles
    }


  },
  actions: {
    /**
     * 获取所有操作
     * @param context 上下文
     * @param forceRefresh 强制刷新
     * @returns {Promise<[]>|[]}
     */
    getAllOperations(context, forceRefresh) {
      if (context.state.allOperations.length === 0 || forceRefresh) {
        return getOperationTree().then(resData => {
          context.commit('setAllOperations', resData.data)
          return context.state.allOperations
        })
      } else {
        return context.state.allOperations
      }
    },
    // 获取被选中的操作
    getCheckedOperations(context, checkedOperations) {
      return context.dispatch('getAllOperations').then(resData => {
        return operationIteration(context.state.allOperations, checkedOperations)
      })
    },


    // 获取所有许可
    getAllPermission(context, forceRefresh) {
      if (context.state.allPermissions.length === 0 || forceRefresh) {
        return findPermission().then(resData => {
          context.commit('setAllPermission', resData.data)
          return context.state.allPermissions
        })
      } else {
        return context.state.allPermissions
      }
    },

    // 获取所有角色
    getAllRoles(context, forceRefresh) {
      if (context.state.allRoles.length === 0 || forceRefresh) {
        return findRole().then(resData => {
          context.commit('setAllRoles', resData.data)
          return context.state.allRoles
        })
      } else {
        return context.state.allRoles
      }
    }
  }
}