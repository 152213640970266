
import { isNull } from '@/utils/fangerUtils'
import { Date } from 'core-js'
import dayjs from 'dayjs'

/**
 * 时间过滤器
 * @param val
 * @param format
 * @returns {string|number}
 */
const dateFilter = (val, format = 'YYYY-MM-DD') => {
    if (val === null || val === undefined) {
        return ''
    }
    else if (val instanceof Array) {
        let reValue = ''
        for (let i = 0; i < val.length; i++) {
            if (Number(val[i]) > 9) {
                reValue = reValue + val[i]
            } else {
                reValue = reValue + '0' + val[i]
            }

            if (i >= 0 && i < 2) {
                reValue = reValue + "-"
            } else if (i === 2) {
                reValue = reValue + " "
            } else if (i > 2 && i < val.length - 1) {
                reValue = reValue + ":"
            }
        }
        if (val.length === 5) {
            reValue = reValue + ":00"
        }
        return reValue.trim()
    }
    else if (!isNaN(val) && typeof val === 'string') {
        try {
            val = parseInt(val)
        } catch (e) {
            return val
        }
    } else if (typeof val === 'string') {
        return val
    }
    if (typeof val === 'number') {
        const valLength = val.toString().length
        if (valLength < 13) {
            val = val * 10 ** (13-valLength)
        }
    }
    
    const reTime = dayjs(val).format(format)
    return reTime
}

export const formateDate = (val, format='YYYY-MM-DD') => {
    return dateFilter(val, format)
}


/**
 * 从数组中获取时间
 * @param {*} val  时间数组
 * @param {*} len  格式化时间的数组长度
 * @returns 
 */
export const formateDataByArray = (val, len = 3,formatType) => {
    if (typeof val === 'string') {
        if (len === 3) {
            return val.split(" ")[0]
        } else {
            return val  
        }
            
    }
    if(typeof val === 'number'){
        return formateDate(val,formatType)
    }
        
    if (isNull(val)) {
        return val
    }
    let reValue = ''
    for (let i = 0; i < val.length; i++) {
        let symbol = ''
        if (i >= 1 && i < 3) {
            symbol =  "-"
        } else if (i === 3) {
            symbol = " "
        } else if (i > 3 && i < val.length) {
            symbol = ":"
        }
        if (Number(val[i]) > 9) {
            reValue = reValue + symbol + val[i]
        } else {
            reValue = reValue + symbol + '0' + val[i]
        }
    }
    if (val.length === 5 && len > 5) {
        reValue = reValue + ":00"
    }
    return reValue.trim()
}
export default app => {
    app.config.globalProperties.$filters = {
        dateFilter
    }
}


/**
 * 时间字符串转时间戳
 * @param {*} timeStr 
 * @returns 
 */
export const getTimeByStr = (timeStr) => {
    if (!isNull(timeStr)) {
        let _timeStr = timeStr.substring(0, 19)
        _timeStr = _timeStr.replace(/-/g, '/')
        const timeInt = new Date(_timeStr).getTime()
        return timeInt
    }
    return timeStr
}