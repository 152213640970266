import axios from "axios";
import { alertBox, errorMsg, succesMsg } from "@/utils/msgBox.ts";
import store from "@/store";
import { ElLoading } from "element-plus";
import { getI18nStr } from "@/utils/i18n";
import { isCheckTimeout } from "@/utils/auth";
import { getDictName } from "@/utils/cache";
import qs from "qs";
import { getItem } from "@/utils/storage";
import { ON_LINE_TIME } from "@/constant";
import { confirmBox } from "./msgBox";
import { isNull } from "./fangerUtils";

// 取消请求的函数
let cancelToken = axios.CancelToken;

export class feRequest {
  constructor(baseURL, timeout) {
    this.service = axios.create({
      baseURL: baseURL,
      timeout: timeout,
    });

    // 未登录标识
    this.unLogin = 0;
    // 保存正在请求
    this.requestDict = {};

    // 存储每个请求的取消函数和 axios 标识
    this.pending = [];

    // 保存上次请求的参数
    this._requestParams = null;

    // 是否正在显示错误提示窗口，以显示后续不在显示
    this.showErrorTrpsFlag = false;

    // 配置请求拦截器
    this.setRequRestInterceptors();
    // 配置响应拦截器
    this.setResponseInterceptors();
  }

  /**
   * 设置请求拦截器
   */
  setRequRestInterceptors() {
    // 请求拦截器
    this.service.interceptors.request.use(
      (config) => {
        if (
          !isNull(config.headers.uniqueRequest) &&
          !isNull(config.headers.uniqueRequest)
        ) {
          // 在一个axios发送前执行一下取消操作
          this.removePending(config);
          config.cancelToken = new cancelToken((fun) => {
            // 唯一标识用来判断是否是同一个请求
            this.pending.push({
              u: config.url + "&" + config.method,
              f: fun,
            });
          });
        }

        if (this.stopRepeatRequest(config.url, config.params)) {
          throw new axios.Cancel("repeat request");
        }
        // 在这里统一注入 token
        if (store.getters.token) {
          if (isCheckTimeout()) {
            // 退出操作
            store.dispatch("user/logout");
            return Promise.reject(new Error("用户登录过期"));
          }
          // console.log('请求参数',config)
          config.headers.Authorization = `${store.getters.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
  // 设置响应拦截器
  setResponseInterceptors() {
    // 响应拦截器
    this.service.interceptors.response.use(
      (response) => {
        this.allowRequest(response.config.url);
        // 判断当前请求是否成功
        if (response.data.status === 1  || response.headers['content-type'] === "application/octet-stream" || response.data.constructor.name === "Blob") {
          return response.data;
        } else {
          if (response.data.message === "TOKEN_OUT_TIME_EXCEPTION") {
            if (this.unLogin === 0) {
              this.unLogin = 1;
              const date = new Date();
              const onLineTime = getItem(ON_LINE_TIME);
              if (
                onLineTime === null ||
                date - new Date(onLineTime) > 1000 * 60 * 11
              ) {
                store.dispatch("user/logout");
                return;
              } else {
                alertBox(
                  "长时间未操作，登录超时，请重新登录",
                  "确定",
                  "warning"
                ).finally(() => {
                  store.dispatch("user/logout");
                  return;
                });
              }
            }
          } else if (response.data.message === "UN_LOGIN") {
            if (this.unLogin === 0) {
              this.unLogin = 1;
              alertBox("当前未登录，请进行登录", "确定", "warning").finally(
                () => {
                  store.dispatch("user/logout");
                  return;
                }
              );
            }
          } else {
            this.unLogin = 0;
            // 失败（请求成功，业务失败），消息提示
            return Promise.reject(response.data);
          }
        }
      },
      (error) => {
        if (error.code !== "ERR_CANCELED") {
          this.allowRequest(error.config.url);
        }
        return Promise.reject(error);
      }
    );
  }

  // 停止重复请求
  stopRepeatRequest(url, params) {
    if (
      this.requestDict[url] &&
      this.requestDict[url] === JSON.stringify(params)
    ) {
      return true;
    }
    this.requestDict[url] = JSON.stringify(params);
    return false;
  }

  /**
   * 删除已经完成的请求
   * @param {*} url
   */
  allowRequest(url) {
    delete this.requestDict[url];
  }
  /**
   * 取消之前的请求
   * @param {*} config
   */
  removePending(config) {
    for (let p in this.pending) {
      if (this.pending[p].u === config.url + "&" + config.method) {
        //当当前请求在数组中存在时执行函数体
        this.pending[p].f(); //执行取消操作
        this.pending.splice(p, 1);
      }
    }
  }

  _request(
    url,
    method,
    params,
    tips,
    loading,
    errTips,
    uniqueRequest,
    responseType,
    dataType,
    contentType,
    timeout
  ) {
    this._requestParams = {
      url,
      method,
      params,
      tips,
      loading,
      errTips,
      errTips,
      uniqueRequest,
      responseType,
      dataType,
      contentType,
      timeout,
    };
    let LoadingInstance;
    if (loading === undefined || loading === true) {
      LoadingInstance = ElLoading.service({
        text: "拼命加载中",
        background: "rgba(255,255,255,0.3)",
      });
    }
    let response = "";
    if (responseType) {
      response = responseType;
    }
    // 参数
    let dataParams = {};
    if (method === "get") {
      dataParams = {
        params: params,
      };
    } else {
      dataParams = {
        data: qs.stringify(params, { skipNulls: true }), // qs 序列化数据 Content-Type:application/x-www-form-urlencoded
        // data: JSON.stringify(params)
      };
    }
    if (timeout) {
      dataParams.timeout = timeout;
    }

    // 请求头
    const headers = {};
    // 判断请求是否唯一请求，有相同的请求，取消前一个请求
    if (!isNull(uniqueRequest) && uniqueRequest) {
      headers.uniqueRequest = uniqueRequest;
    }
    if (dataType) {
      headers["data-type"] = dataType;
    }
    if (contentType) {
      headers["content-type"] = contentType;
    }
    return new Promise((resolve, reject) => {
      this.service
        .request({
          url: url,
          method: method,
          headers: headers,
          responseType: response,
          ...dataParams,
        })
        .then(async (data) => {
          if (tips && data.status === 1) {
            succesMsg(getDictName("businessCode", data.message));
          } else if (data && data.status === 2) {
            const confirm = await confirmBox(data.message, "确定", "warning");
            if (confirm === "confirm") {
              this._requestParams.params.check = 0;
              return _request(...this._requestParams);
            }
          }
          if(data){
            resolve(data);
          }
        })
        .catch((err) => {
          if (err.type && err.type === "application/octet-stream") {
            // 下载文件
            resolve(err);
          } else if (
            err.message &&
            (err.message === "repeat request" || err.message === "canceled")
          ) {
            // 重复请求或被取消的请求，不进行任何操作
            reject(err);
          } else {
            if (errTips === undefined || errTips) {
              // 是否进行错误提示
              // 获取错误信息
              let errMessage = "";
              if (err.status === -1) {
                errMessage = getDictName("businessCode", err.message);
              } else {
                if (
                  [
                    "Request failed with status code 500",
                    "Request failed with status code 502",
                    "Request failed with status code 503",
                    "Request failed with status code 504",
                  ].includes(err.message)
                ) {
                  errMessage = "系统正在维护，请稍后重试！";
                } else {
                  errMessage = getI18nStr("msg.axios." + err.message);
                }
              }
              if (errMessage === null) {
                // errorMsg('错误信息为空') // 错误提示
              } else if (errMessage.indexOf("timeoutof") === -1) {
                // errorMsg(errMessage) // 错误提示
                if (!this.showErrorTrpsFlag) {
                  this.showErrorTrpsFlag = true;
                  alertBox(errMessage, "确定", "error")
                    .then((res) => {})
                    .catch((err) => {})
                    .finally(() => {
                      this.showErrorTrpsFlag = false;
                    });
                }
              } else {
                errorMsg("连接超时"); // 错误提示
              }
            }
            reject(err);
          }
        })
        .finally(() => {
          LoadingInstance && LoadingInstance.close();
        });
    });
  }

  /**
   * get 请求
   * @param {*} params
   * @returns
   */
  axiosGet(params) {
    return this._request(
      params.url,
      "GET",
      params.data,
      params.tips,
      params.loading,
      params.errTips
    );
  }

  /**
   * post 请求
   * @param {*} params
   * @returns
   */
  axiosPost(params) {
    let contentType = undefined;
    if (params.contentType) {
      contentType = params.contentType;
    }
    return this._request(
      params.url,
      "POST",
      params.data,
      params.tips,
      params.loading,
      params.errTips,
      params.uniqueRequest,
      params.responseType,
      params.dataType,
      contentType,
      params.timeout
    );
  }

  /**
   * 导出文件
   * @param {*} params
   */
  axiosPostDownload(params) {
    return this._request(
      params.url,
      "POST",
      params.data,
      params.tips,
      params.loading,
      params.errTips,
      params.uniqueRequest,
      "blob",
      "json",
      "application/x-www-form-urlencoded;charset=UTF-8"
    ).then((resData) => {
      if (resData?.size > 0) {
        this.downfiles(resData);
      }
      return Promise.resolve(resData)
    });
  }

  /**
   * 下载文件
   * @param {*} data
   */
  downfiles(data) {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
    });
    let url = window.URL.createObjectURL(blob); // 创建一个临时 url 指向 blob 对象
    let a = document.createElement("a");
    a.href = url;
    a.download = new Date().getTime();
    a.click();
    // 释放这个临时的对象 url
    window.URL.revokeObjectURL(url);
  }

  /**
   * 下载 http 网址文件
   * @param {*} httpAddr
   */
  downFilesByHttp(httpAddr, name = null) {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.href = httpAddr;
    a.style.display = "none";
    if (name !== null) {
      a.download = name;
    }
    a.click();
    document.body.removeChild(a);
  }
}
