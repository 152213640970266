import {findChildLibraryByCode} from "@/api/sys/dict";

/**
 * 后台字典等数据的缓存
 */

export default {
  namespaced: true,
  state: () => ({
    dictOption: {}
  }),
  mutations: {
    setDictOption(state, dictItem) {
      state.dictOption[dictItem.dictKey] = dictItem.dictVal
    }

  },
  actions: {
    getDictOption({commit},dictKey) {
      return new Promise((resolve, reject) => {
        findChildLibraryByCode(dictKey).then(resData => {
          commit('setDictOption',{dictKey:dictKey,dictVal:resData.data})
          resolve(resData)
        })
      })
    }
  }
}






