/**
 * 注册自定义全局组件
 */

import card from "./card"

const components = {
  fztCard: card
}

export default (app) => {
  for (const comKey in components) {
    app.component(comKey,components[comKey])
  } 
}