/**
 * 快捷访问
 */
// import { generateColors } from '@/utils/theme'
// import { MAIN_COLOR } from '@/constant'
const getters = {
    token: state => state.user.token,
    userId: state => state.user.userId,
    simplePassword: state => state.user.simplePassword,
    loginInfo: state => state.user.loginInfo,
    language: state => state.app.language,
    tagsViewDict: state => state.app.tagsViewDict,
    curTagsViewDict: state => state.app.curTagsViewDict,
    dataCurrentTag: state => state.app.data_current_tag,
    currentNodeData: state => state.data.currentNodeData,
    sidebarOpened: state => state.app.sidebarOpened,
    userEntity: state => state.user.userEntity,
    routers: state => state.user.routers,
    permissionRoutes: state => state.permission.permissionRoutes,
    navigationRouter: state => state.user.navigationRouter,
    operationList: state => state.permission.operationList,
    dictOption: state => state.cache.dictOption,
    treeMask: state => state.data.treeMask,
    cssVar: state => {
        return {
            ...state.theme.variables,
            // ...generateColors(getItem(MAIN_COLOR))
        }
    },
    permissionTrees: state => state.permission.permissionTrees,
}



export default getters
