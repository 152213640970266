/**
 * 字典管理
 */

import {axiosPost} from "@/utils/request";

/**
 * 查询所有字典的类型标识
 */
export const findLibrary = (data) => {
  return axiosPost({
    url: '/ap-system/findLibrary',
    data: {
      libraryParentId: '0',
      ...data,
    },
    loading: false
  })
}

/**
 * 返回字典表的所有数据
 * @returns {Promise<unknown>}
 */
export const findAllLibrary = () => {
  return new Promise((resolve, reject) => {
    axiosPost({
      url: '/ap-system/findLibrary',
      data: {
        pageIndex: 1,
        pageSize: 100000,
      }
    }).then(res => {
      resolve({
        total: res.pageParam.totleInfo,
        data: res.data
      })
    })
  })
}

/**
 * 查询子集字典
 */
export const findSubsetLibrary = (requestData) => {
  const libraryCode = requestData.parentRequestParams.libraryParentCode
  return new Promise((resolve, reject) => {
    if (libraryCode === '' || libraryCode === null) {
      resolve({
        total: 0,
        data: []
      })
    } else {
      axiosPost({
        url: '/ap-system/findChildLibrary',
        data: {
          libraryCode: libraryCode
        }
      }).then(data => {
        resolve({
          total: null,
          data: data.data
        })
      })
    }
  })
}
/**
 * 查询子集字典根据 libraryCode
 * @param {*} libraryCode 
 * @returns 
 */
export const findChildLibraryByCode = (libraryCode) => {
  return axiosPost({
    url: '/ap-system/findChildLibrary',
    data: {
      libraryCode: libraryCode,
    },
    loading: false
  })
}
/**
 * 查询子集字典根据 parentId
 * @param {*} parentId 
 * @returns 
 */
export const findChildLibraryByParentId = parentId => {
  return axiosPost({
    url: '/ap-system/findLibrary',
    data: {
      libraryParentId: parentId,
      pageIndex: 1,
      pageSize: 1000,
    },
    loading: false
  })
}

/**
 * 保存父级字典
 */
export const saveParentLibrary = (data) => {
  return axiosPost({
    url: '/ap-system/saveLibrary',
    data: {
      libraryCode: data.libraryCode,
      libraryName: data.libraryName,
      libraryDesc: data.libraryDesc,
    }
  })
}

/**
 * 保存子集字典
 */
export const saveSubsetLibrary = (data) => {
  return axiosPost({
    url: '/ap-system/saveLibrary',
    data: {
      libraryCode: data.libraryCode,
      libraryName: data.libraryName,
      libraryDesc: data.libraryDesc,
      libraryParentId: data.libraryParentId,
      aaa: 123,
      libraryCreatetime: new Date('2022-05-01')

    }
  })
}


/**
 * 修改字典
 */
export const modifyParentLibrary = (data) => {
  return axiosPost({
    url: '/ap-system/modifyLibrary',
    data: {
      libraryId: data.libraryId,
      libraryCode: data.libraryCode,
      libraryName: data.libraryName,
      libraryDesc: data.libraryDesc
    }
  })
}
/**
 * 删除字典
 */
export const removeLibrary = (data) => {
  return axiosPost({
    url: '/ap-system/removeLibrary',
    data: {
      libraryId: data.libraryId,
    }
  })
}