/**
 * 处理字符串的工具
 */

/**
 * 将列表转为字符串用逗号分割
 * @param inStr 传入字符串，列表的内容在字符串之后进行拼接
 * @param strArray 传去的字符串数组
 * @returns {*}
 */
export const joinComma = (inStr, strArray) => {
  return joinSymbol(inStr,strArray,',')
}

export const joinSymbol = (inStr,strArray,symbol) => {
  let outStr = inStr
  strArray.forEach(strItem => {
    if (strItem !== '') {
      if (outStr === '') {
        outStr = strItem
      } else {
        outStr = outStr + symbol + strItem
      }
    }
  })
  return outStr
}

/**
 * 获取节点类型Id key 的字符串
 * @param typeStr
 * @returns {string}
 */
export const getNodeTypeIdStr = typeStr => {
  if (typeStr === 'company_unit'){
    return 'companyUnitId'
  } else {
    return `${typeStr}Id`
  }
}


