import {axiosPost} from '@/utils/request'

/**
 * 登录
 * return
 *
 */
export const login = data => {
  return axiosPost({
    url: '/ap-system/UserLogin',
    data: data,
    loading: false,
    errTips: false
  })
}

/**
 * 退出登录
 */

export const logout = data => {
  return axiosPost({
    url: '/ap-system/logout',
    data: data
  })
}
