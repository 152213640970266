/**
 * 角色相关api
 */
import {axiosPost} from "@/utils/request";

/**
 * 查询所有角色
 * @returns {Promise | Promise<unknown>}
 */
export const findRole = () => {
  return axiosPost({
    url: '/ap-system/findRole'
  })
}

/**
 * 添加
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const saveRole = (data) => {
  return axiosPost({
    url: 'ap-system/saveRole',
    data: data,
    tips: true,
    loading: false
  })
}

/**
 * 修改
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const modifyRole = (data) => {
  delete data.roleCreatetime
  return axiosPost({
    url: 'ap-system/modifyRole',
    data: data,
    tips: true,
    loading: false
  })
}
/**
 * 删除
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const removeRole = (data) => {
  return axiosPost({
    url: 'ap-system/removeRole',
    data: {
      roleId: data.roleId
    },
    loading: false
  })
}


export const addPermission = (data) => {
  return axiosPost({
    url: 'ap-system/savePermissionRoleRel',
    data: {
      roleId: data.roleId,
      permissionId: data.permissionId
    },
    tips: true,
    loading: false
  })
}

/**
 * 获取角色下的许可
 * @param data
 */
export const getPermission = (data) => {
  return axiosPost({
    url: 'ap-system/getPermissionByRole',
    data: data,
    loading: false
  })
}
