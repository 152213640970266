<template>
  <div id="app">
    <span id="ztree_7161827528_31_ico" title="" treenode_ico="" class="button ico_close"
      style="background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFISURBVHgBpZMxS8NQFIXPS5NYE4niKHHQwaEuLk6CDk7ulu6Ku+APEHfBH+BPqHtBcBHUwcFJwdVmcJRQEyXPxHeeJVYTSaQHEkK497v33MsVmRLGkMnXQGZ4STKkNVGmADxLYEp9GPwR/iOZUvV0wbwDOUxeuxggiKpJV5sufMf4BozqdLWJltcoJD2EKTzVsu+IPJkyfgd6poFukKDbT3QgH/o9un9DEKc/kksBVNu3cP4s0bmOlKUUJ4/vuqvteasQWwpgld6Gi9Z0A1uXkYJ94HB5oiy0HJDbYUHOVPwdUwrgWvduY5w9SdWJg7ZqnZ1wkJWAUKYq+FVDmEw7+0s2dhdtHNzFBUBhjdxCb93Vkx8VITsLVjWgcxOhSscrTW2LEjymvtovT4orqyN2N2sLzE0aXwAeEz3LmvdgKHczw2MS457zJ6eGe6XFUKEhAAAAAElFTkSuQmCC) 0 0 no-repeat;"></span>

    <template v-if="isShow">
      <router-view v-slot="{ Component }">
        <transition>
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </transition>
      </router-view>
    </template>
    <template v-else>
      <span style="font-size: 30px;white-space: pre-wrap;">
        {{showText}}
      </span>
      
    </template>

    <!--    <router-view/>-->
  </div>
</template>

<script setup>
import * as echarts from 'echarts'
import { provide, ref } from "vue";
provide('echart', echarts)

const isShow = ref(true)
const showText = ref("")

const isShowFun = () => {
  let uA = navigator.userAgent.toLowerCase()
  var ipad = uA.match(/ipad/i) == "ipad";
    var iphone = uA.match(/iphone os/i) == "iphone os";
    var midp = uA.match(/midp/i) == "midp";
    var uc7 = uA.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var uc = uA.match(/ucweb/i) == "ucweb";
    var android = uA.match(/android/i) == "android";
    var windowsce = uA.match(/windows ce/i) == "windows ce";
    var windowsmd = uA.match(/windows mobile/i) == "windows mobile"; 
    if (!(ipad || iphone || midp || uc7 || uc || android || windowsce || windowsmd)) {
      isShow.value = true
      
    }else{
      isShow.value = false
      showText.value = "暂时不支持手机端访问\r\n请使用电脑进行访问！"
    }
}

isShowFun()



</script>


